<template>
  <recebimento-dados-nivel-1-agrupado
    v-if="indModoVarejo"
    ref="nivel_1_cards"
    @Recebimento__SelecionaItemNivel1="selectItem">
  </recebimento-dados-nivel-1-agrupado>
  <pagamento-dados-nivel1-agrupado
    v-else
    :visao="visao"
    ref="nivel_1_cards"
    @Pagamento__SelecionaItemNivel1="selectItem">
  </pagamento-dados-nivel1-agrupado>
</template>

<script>
import { mapGetters } from 'vuex';
import PagamentoDadosNivel1Agrupado from './pagamento/PagamentoDadosNivel1Agrupado';
import RecebimentoDadosNivel1Agrupado from './recebimento/RecebimentoDadosNivel1Agrupado';

export default {
  data() {
    return {
      visao: 'Status',
    };
  },
  components: {
    RecebimentoDadosNivel1Agrupado,
    PagamentoDadosNivel1Agrupado,
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
  },
  methods: {
    selectItem(item, router, visaoSelecionada) {
      if (visaoSelecionada === 'LIQUIDACAO_LISTA') {
        this.$router.push({
          name: router,
          params: {
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Status') {
        this.$router.push({
          name: router,
          params: {
            status: item.status,
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Cliente') {
        this.$router.push({
          name: router,
          params: {
            idCliente: item.idCliente,
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else {
        this.$router.push({
          name: router,
          params: {
            idFornecedor: item.idFornecedor,
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      }
    },
  },
  beforeMount() {
    this.visao = this.$route.params.visao ? this.$route.params.visao : 'Status';
  },
};
</script>
